import {CSSStyles} from '../../types/CSSStyles';

import {css} from '@emotion/react';
import {CSSProperties} from '@emotion/serialize';
import React from 'react';

export interface Props {
  readonly src: string;
  readonly backgroundPosition?: CSSProperties['backgroundPosition'];
  readonly backgroundSize?: CSSProperties['backgroundSize'];
  readonly children?: React.ReactNode;
  readonly height?: number | string;
  readonly sx?: CSSStyles;
  readonly width?: number | string;
}

export default function BackgroundImageDiv(props: Props): JSX.Element {
  const {backgroundPosition, backgroundSize, children, height, src, sx, width} =
    props;
  return (
    <div
      css={[
        css({
          height,
          width,
          backgroundImage: `url(${src})`,
          backgroundSize,
          backgroundPosition,
        }),
        sx,
      ]}
    >
      {children}
    </div>
  );
}
